import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/fontawesome-free-brands/faFacebook';
import { faInstagram } from '@fortawesome/fontawesome-free-brands/faInstagram';
import { faLinkedin } from '@fortawesome/fontawesome-free-brands/faLinkedin';

import Container from '@/components/atoms/Container';
import Grid from '@/components/atoms/Grid';
import Link from '@/components/atoms/Link';
import Typography from '@/components/atoms/Typography';
import NewsletterForm from '@/components/molecules/NewsletterForm';

import mq from '@/styles/mq';

import Company from '@/domain/entities/Company';

interface Props {
  companyInfos: Company;
  type: `usine` | `magasin`;
}

const FooterRoot = styled('div')`
  background-color: ${({ theme }) => theme.color.secondary.main};
`;
const FooterContainer = styled(Container)`
  padding-top: ${({ theme }) => theme.spacing(5)};
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

  ${mq('lg')} {
    padding-top: ${({ theme }) => theme.spacing(12)};
    max-width: ${({ theme }) => theme.breakpoints.lg}px;
  }
`;
const AddressGrid = styled(Grid)`
  display: flex;
  flex-direction: row;

  ${mq('lg')} {
    flex-direction: column;
  }
`;
const NewsletterGrid = styled(Grid)`
  align-items: center;
  ${mq('lg')} {
    align-items: flex-end;
  }
`;
const LegalGrid = styled(Grid)`
  ${mq('lg')} {
    margin-top: ${({ theme }) => theme.spacing(15)};
    padding-bottom: ${({ theme }) => theme.spacing(12)};
  }
`;
const Column = styled(Grid)`
  margin-bottom: ${({ theme }) => theme.spacing(5)};

  & > div {
    text-align: center;
    width: 100%;
  }

  ${mq('lg')} {
    margin-bottom: ${({ theme }) => theme.spacing(2)};

    & > div {
      text-align: left;
    }
  }
`;
const Text = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(1.5)};
`;
const Socials = styled('div')`
  margin-top: ${({ theme }) => theme.spacing(2.5)};
`;
const SocialIcon = styled(FontAwesomeIcon)`
  font-size: 2rem;
  color: ${({ theme }) => theme.color.textSecondary.main};
`;
const Facebook = styled(SocialIcon)`
  margin-right: ${({ theme }) => theme.spacing(2)};
`;
const Instagram = styled(SocialIcon)`
  margin-right: ${({ theme }) => theme.spacing(2)};
`;
const Linkedin = styled(SocialIcon)``;
const LegalLink = styled(Text)`
  ${mq('lg')} {
    width: 100%;
  }
`;
const Legals = styled(Grid)`
  margin-top: ${({ theme }) => theme.spacing(5)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${mq('lg')} {
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 0;

    & ${LegalLink}:not(:last-of-type) {
      margin-right: ${({ theme }) => theme.spacing(5)};
    }
  }
`;
const Copyright = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(10)};
  padding-bottom: ${({ theme }) => theme.spacing(5)};
  text-align: center;

  ${mq('lg')} {
    margin-top: 0;
    padding-bottom: 0;
    text-align: right;
  }
`;

const Shoplinks = [
  { label: 'Accueil', path: '/' },
  { label: 'Magasin', path: '/magasin' },
  { label: 'Produits', path: '/magasin/produits' },
  { label: 'Services', path: '/magasin/services' },
  { label: 'Á propos', path: '/magasin/a-propos' },
  // { label: 'Actualités', path: '/magasin/actualites' },
  { label: 'Contact', path: '/magasin/contact' },
];
const IndustryLinks = [
  { label: 'Accueil', path: '/' },
  { label: 'Usine', path: '/usine' },
  { label: 'Services', path: '/usine/services' },
  { label: 'Environnement', path: '/usine/environnement' },
  { label: 'Fabrication', path: '/usine/fabrication' },
  { label: 'Á propos', path: '/usine/a-propos' },
  { label: 'Contact', path: '/usine/contact' },
];
const legalShopLinks = [
  { label: 'Faqs', path: '/magasin/faqs' },
  { label: 'Mentions légales', path: '/magasin/mentions-legales' },
  { label: 'Traitement des données', path: '/magasin/traitement-des-donnees' },
  { label: 'Conditions de vente', path: '/magasin/conditions-de-vente' },
];
const legalIndustryLinks = [
  { label: 'Mentions légales', path: '/usine/conditions-de-vente' },
  { label: 'Traitement des données', path: '/usine/traitement-des-donnees' },
  { label: 'Conditions de vente', path: '/usine/conditions-de-vente' },
];

const Footer = ({ companyInfos, type }: Props): JSX.Element => {
  const links = type === `usine` ? IndustryLinks : Shoplinks;
  const Links = links?.map((el, i) => (
    <Text color="textSecondary" key={i.toString()}>
      <Link to={el?.path}>{el?.label}</Link>
    </Text>
  ));

  const linksLegal = type === `usine` ? legalIndustryLinks : legalShopLinks;
  const LegalLinks = linksLegal?.map((el, i) => (
    <LegalLink color="textSecondary" key={i.toString()}>
      <Link to={el?.path}>{el?.label}</Link>
    </LegalLink>
  ));

  const ElementsPerColumn = 4;

  const Columns = Links?.reduce((acc: any, current: JSX.Element, index) => {
    if (index % ElementsPerColumn === 0) acc.push([]);
    acc[acc.length - 1].push(current);
    return acc;
  }, [])?.map((column: JSX.Element, index: number) => {
    return (
      <Column
        container
        flexDirection="column"
        xxs={6}
        lg={2}
        key={index.toString()}
      >
        {column}
      </Column>
    );
  });

  const {
    street,
    address_additional,
    postal_code,
    city,
    phone_number,
    email,
    bank,
  } = companyInfos || {};

  return (
    <FooterRoot>
      <FooterContainer>
        <Grid container>
          <AddressGrid xxs={12} lg={2}>
            <Column xxs={6} lg={12}>
              <Text color="textSecondary">{street}</Text>
              <Text color="textSecondary">{address_additional}</Text>
              <Text color="textSecondary">
                {postal_code} - {city}
              </Text>
            </Column>
            <Column xxs={6} lg={12}>
              <Text color="textSecondary">{phone_number}</Text>
              <Text color="textSecondary">{email}</Text>
              <Text color="textSecondary">{bank}</Text>
            </Column>
          </AddressGrid>
          {typeof window !== 'undefined' &&
            window.location.pathname !== '/' &&
            Columns}
          {typeof window !== 'undefined' && window.location.pathname === '/' && (
            <Grid lg={6}>
              <Grid xxs={12} lg={7}>
                <Legals
                  css={css`
                    display: flex;
                    align-items: center !important;
                    ${mq('lg')} {
                      flex-direction: column !important;
                      align-items: flex-start !important;
                    }
                  `}
                >
                  {LegalLinks}
                </Legals>
              </Grid>
              <Grid xxs={12} lg={5}>
                <Copyright
                  color="textSecondary"
                  css={css`
                    text-align: center !important;
                    ${mq('lg')} {
                      text-align: left !important;
                    }
                  `}
                >
                  © {new Date().getFullYear()} Guilmin
                </Copyright>
              </Grid>
            </Grid>
          )}
          <NewsletterGrid
            container
            flexDirection="column"
            lg={
              typeof window !== 'undefined' && window.location.pathname !== '/'
                ? 6
                : 4
            }
          >
            <NewsletterForm type={type} />
            <Socials>
              <Link to="https://www.facebook.com/Guilmin.Ets" target="_blank">
                {/* @ts-ignore */}
                <Facebook icon={faFacebook} />
              </Link>
              <Link to="https://www.instagram.com/sa_guilmin/" target="_blank">
                {/* @ts-ignore */}
                <Instagram icon={faInstagram} />
              </Link>
              <Link
                to="https://www.linkedin.com/company/guilmin-sa/about/"
                target="_blank"
              >
                {/* @ts-ignore */}
                <Linkedin icon={faLinkedin} />
              </Link>
            </Socials>
          </NewsletterGrid>
        </Grid>
        {typeof window !== 'undefined' && window.location.pathname !== '/' && (
          <LegalGrid container>
            <Grid xxs={12} lg={9}>
              <Legals>{LegalLinks}</Legals>
            </Grid>
            <Grid xxs={12} lg={3}>
              <Copyright color="textSecondary">
                © {new Date().getFullYear()} Guilmin
              </Copyright>
            </Grid>
          </LegalGrid>
        )}
      </FooterContainer>
    </FooterRoot>
  );
};

export default Footer;
